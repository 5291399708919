import React from 'react'
import PressLayout from '../../components/pressLayout'
import Share from '../../components/share'
import { Link } from 'gatsby'

export default function CTVCurbsidePickup () {
  const shareTitle = 'Christmas Cheer Breakfast sold out in-person, but plenty of goodies for virtual attendees'
  const articleLink = 'https://ottawa.ctvnews.ca/christmas-cheer-breakfast-sold-out-in-person-but-plenty-of-goodies-for-virtual-attendees-1.5670637'
  const videoLink = 'https://ottawa.ctvnews.ca/video?clipId=2324860'

  return (
    <PressLayout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>Christmas Cheer Breakfast sold out in-person, but plenty of goodies for virtual attendees</h1>

              <header className="article-header">
                <div className="article-details">
                  <span className='news'>News</span>
                  <span>Nov 17, 2021</span>
                </div>

                <Share shareTitle={shareTitle} />
              </header>

              <div className="article-body">
                <img src="/img/press/christmas-cheer-breakfast.jpg" alt="Christmas Cheer Breakfast sample delivery" />
                <div className="snippet">The following preview is from <a href={articleLink} target="_blank" rel="noreferrer">CTV Ottawa</a></div>
                <p>“We’re super happy to be involved,” says Alok Ahuja, CEO and founder of Trexity. “After 70 years, it’s become an institution, the Christmas Cheer Breakfast, so when we were approached to get involved, we were super happy to do so; we will be donating all of the deliveries for the virtual tables.”</p>
                <p>Money raised benefits 22 charities, including the Ottawa Food Bank.</p>
                <p><a href={articleLink} target="_blank" rel="noreferrer">Read more on CTV Ottawa</a></p>
                <a href={videoLink} target="_blank" rel="noreferrer">Watch interview on CTV Ottawa</a>
              </div>

              <footer className="article-footer">
                <div className="article-next">
                  <Link to="/press/ctv-ottawa-curbside-pickup" className='cta-link'>Related Article <i className="material-icons">keyboard_arrow_right</i></Link>
                  <p>Curbside Pickup features Trexity on CTV Ottawa evening news</p>
                </div>

                <Share shareTitle={shareTitle} />
              </footer>
            </div>
          </div>
        </div>
      </section>
    </PressLayout>
  )
}
